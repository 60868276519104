export default [
    {
        path: '/slack-transactions',
        component: () => import('../components/TransactionsParent.vue'),
        children: [
            {
                path: '/slack-transactions',
                component: () => import('../components/SlackTransactions.vue'),
                name: 'slack-transactions',
                meta: {                  
                }
            },
            {
                path: '/disk-quota-transactions',
                component: () => import('../components/DiskQuotaTransactions.vue'),
                name: 'disk-quota-transactions',
                meta: {                  
                }
            },
            
        ]
    },

]
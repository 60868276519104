import auth from './auth/routes'
import adminProfile from './adminProfile/routes'
import adminDashboard from './adminDashboard/routes'
import roleAndPermission from './roleAndPermission/routes'
import orders from './orders/routes'
import myTasks from './myTasks/routes'
import exhibitions from './exhibitions/routes'
import users from './users/routes'
import artLibrary from './artLibrary/routes'
//import photoLibrary from './photoLibrary/routes'
//import frameLibrary from './frameLibrary/routes'
//import designLibrary from './designLibrary/routes'
import adminCrowdFunding from './adminCrowdFunding/routes'
import financeAndAudit from './financeAndAudit/routes'
import promotions from './promotions/routes'
import referrals from './referrals/routes'
import feedbacks from './feedbacks/routes'
import errorsLog from './errorsLog/routes'
import activityLog from './activityLog/routes'
import contactUs from './contactUs/routes'
import emailTemplates from './emailTemplates/routes'
import localization from './localization/routes'
import masterData from './masterData/routes'
import settings from './settings/routes'
import transactions from './transactions/routes'

export default [
	...auth,
	...adminProfile,
	...adminDashboard,
	...roleAndPermission,
	...orders,
	...myTasks,
	...exhibitions,
	...users,
	...artLibrary,
	//...photoLibrary,
	//...frameLibrary,
	//...designLibrary,
	...adminCrowdFunding,
	...financeAndAudit,
	...promotions,
	...referrals,
	...feedbacks,
	...errorsLog,
	...activityLog,
	...contactUs,
	...emailTemplates,
	...localization,
	...masterData,
	...settings,
	...transactions,
	
];

 

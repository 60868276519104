<template>
	<div>
		<div class="flex items-center pb-1 font-nunito">
           <div class="block lg:hidden w-40 h-[60px] md:h-12 flex justify-center items-center bg-white shadow-logoShadow rounded-br-[40px] flex items-center">
                <img src="/images/artlogo.webp" class="w-24 object-contain">
            </div>             
            <div class="shadow-logoShadow rounded-bl-[40px] w-full ml-3 xl:ml-6 h-[50px] xl:h-[65px] flex justify-end bg-black bg-opacity-75">
                <div class="flex px-2 lg:px-4 items-center uppercase font-semibold text-[10px] xl:text-sm tracking-wider w-full flex justify-end">
                    <div class="hidden md:block md:flex w-full h-full items-center">
                        <a href="/" class="md:flex justify-center items-center w-3/12 xl:w-4/12 text-center hover:bg-[#022770] px-1 py-2 rounded text-center mx-2 text-white">
                        <!-- Home -->{{$t('home')}}
                        </a>
                        <hr class="flex border-l border-gray-300 h-full">

                        <a href="search" class="md:flex justify-center items-center w-3/12 xl:w-5/12 text-center hover:bg-[#022770] px-1 py-2 rounded text-center mx-2 text-white" ><!-- Art Gallery -->{{$t('art-gallery')}}</a>
                        <hr class="flex border-l border-gray-300 h-full">

                        <a href="search" class="md:flex justify-center items-center w-3/12 xl:w-5/12 text-center hover:bg-[#022770] px-1 py-2 rounded text-center mx-2 text-white" ><!-- Photo Gallery -->{{$t('photo-gallery')}}</a>
                         <hr class="border-l border-gray-300 h-full">
                        
                        <a href="javascript:void(0)" title="coming soon..." class="md:flex justify-center items-center w-2/12 lg:w-3/12 xl:w-4/12 text-center cursor-not-allowed text-gray-300 px-1 py-2 rounded text-center mx-2 text-gray-300" >
                        <!-- Exhibit -->{{$t('exhibit')}}
                        </a>
                        <hr class="border-l border-gray-300 h-full">
                        
                        <a href="javascript:void(0)" title="coming soon..." class="md:flex justify-center items-center w-4/12 lg:w-5/12 text-center cursor-not-allowed text-gray-300 px-1 py-1.5 rounded text-center mx-2" >
                        <!-- Art/Photo Exhibition -->{{$t('art-photo-exhibition')}}
                        </a> 
                        <hr class="border-l border-gray-300 h-full">
                    </div>
                    
                    <div class="flex items-center justify-center w-full md:w-5/12 lg:w-4/12 2xl:w-3/12 gap-1 xl:gap-2">
                        <div class="relative flex items-center">
                            <button type="button" @click="dropUser = !dropUser">
                                <img v-if="profile_image"  :src="profile_image" class="w-4 md:w-6 h-4 md:h-6 xl:w-8 xl:h-8 border border-gray-300 hover:border-bgBlue rounded-full object-cover">
                                <img v-if="!profile_image"  src="/images/user.png" class="w-4 md:w-6 h-4 md:h-6 xl:w-8 xl:h-8 border border-gray-300 hover:border-bgBlue rounded-full object-cover">
                            </button>

                            <button v-if="dropUser" @click="dropUser = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                            <div v-if="dropUser" class="absolute top-12 -right-4 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-36 z-30">
                                <a href="javascript:void(0)" title="coming soon..." class="cursor-not-allowed px-2.5 py-2.5 text-bgBlue text-gray-400 rounded-md w-full flex" >{{$t('dashboard')}}
                                </a>
                                <router-link :to="{name:'admin-profile'}" @click="dropUser = false" class="hover:bg-bgBlue px-1.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex" >{{$t('profile')}}
                                </router-link>
                                <button type="button" class="hover:bg-bgBlue px-1.5 text-bgBlue py-2.5 hover:text-white rounded-md w-full flex uppercase font-nunito tracking-wider" @click="funcSignout()">{{$t('sign-out')}}</button>
                            </div>
                        </div>
                        
                        <div class="w-6 md:w-8 relative">
                            <button @click="dropNotification = !dropNotification" class="relative flex justify-center">
                                <svg class="fill-current text-white w-4 md:w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="M109.5 883V762h90V518.5q0-94 54.5-170t145-98V229q0-33.583 23.708-57.042Q446.417 148.5 480 148.5t57.292 23.458Q561 195.417 561 229v21.5q91.5 22 145.5 97.5t54 170.5V762h91v121h-742ZM481 1003q-37.45 0-63.725-25.981Q391 951.037 391 914h179q0 37.5-25.981 63.25Q518.037 1003 481 1003Z"/></svg>
                                <label class="absolute -top-2 -right-2 text-[8px] md:text-[10px] bg-red-500 text-white rounded-full h-4 md:h-5 w-4 md:w-5 flex justify-center items-center" v-if="notificationCount && notificationCount > 0">{{notificationCount ? notificationCount : ''}}</label>
                            </button>
                            <button v-if="dropNotification" @click="dropNotification = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>  
                            <div v-if="dropNotification" class="absolute top-12 -right-24 md:-right-4 bg-white border backdrop-filter backdrop-blur-md bg-opacity-30 rounded text-xs w-64 h-96 capitalize z-30 overflow-auto">
                                <div v-if="notifications.length" class="divide-y divide-gray-400">
                                    <div v-for="(item,i) in notifications" :key="i" class="h-full">
                                        <button type="button" class="hover:bg-gray-200 px-2.5 py-2.5 text-gray-700 hover:text-gray-800 rounded-md w-full flex cursor-pointer" @click="markAsSeen(item)">
                                            <label class="font-semibold text-left">{{item.type+' : '}}
                                                <span class="font-normal">{{item.message}}</span>
                                            </label>
                                        </button>
                                    </div>
                                </div>
                                <div v-else class="flex justify-center items-center h-full">
                                    <label class="px-2.5 py-2.5 flex justify-center items-center h-full">You don't have any notification</label>
                                </div>
                            </div>
                        </div>

                        <div class="relative flex justify-center items-center rounded h-9 px-1.5 border hover:border-gray-400 bg-white" >
                            <div class="w-16 xl:w-28 2xl:w-40 truncate flex items-center justify-between gap-1 lg:gap-2" @click="dropLang = !dropLang">
                                <svg class="w-2.5 lg:w-4 pointer-events-none hidden xl:block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path>
                                </svg>
                                <span class="flex truncate">{{ languageName }}</span>
                                <svg class="w-2 lg:w-3.5 flex items-center justify-center pointer-events-none transition duration-500 ease-in-out" :class="{ 'rotate-180 transition duration-500 ease-in-out' : dropLang }" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L7 8L14 0H0Z" fill="black"/></svg>
                            </div>
                            <div v-if="dropLang" class="absolute top-12 z-30">
                                <ul class="bg-white backdrop-filter border backdrop-blur-md bg-opacity-30 rounded text-xs w-28">
                                    <li
                                        v-for="(language, index) in languages" :key="index"
                                        @click="selectLang(language.slug,language.name); dropLang = false"
                                        class="hover:bg-bgBlue p-1.5 text-bgBlue hover:text-white rounded-md">
                                        {{ language.name }}
                                    </li>
                                </ul>
                            </div>
                            <button v-if="dropLang" @click="dropLang = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-10"></button>
                        </div> 
                        <div>
                            <a href="#" class="flex items-center justify-center">
                                <svg class="fill-current text-white w-[20px] lg:w-[28px] h-[22px]" fill="none" viewBox="0 0 32 25">
                                    <path fill="" d="M31.1024 4.56337c-.0931-.12102-.2128-.21909-.3497-.28669-.1369-.06761-.2876-.10295-.4403-.10331H7.21243l-.45-1.43c-.04943-.15355-.13527-.29285-.2502-.40603-.11492-.11319-.25552-.19689-.4098-.24397l-4.1-1.260004C1.87636.794626 1.7439.781096 1.6126.79355c-.1313.012453-.25886.050646-.3754.112397-.23535.124713-.411531.337813-.48977.592423-.078239.2546-.052131.52986.072581.76522.124711.23536.337809.41154.592419.48978l3.59 1.1 4.58 14.47003-1.63 1.34-.13.13c-.4031.4667-.63148 1.0591-.64608 1.6756-.01459.6166.18551 1.2191.56608 1.7044.27256.3314.61891.5945 1.01134.7681.39243.1737.82005.2531 1.24863.2319h16.69c.2652 0 .5196-.1054.7071-.2929.1876-.1876.2929-.4419.2929-.7071 0-.2653-.1053-.5196-.2929-.7071-.1875-.1876-.4419-.2929-.7071-.2929H9.84243c-.11515-.004-.22735-.0375-.32574-.0975-.0984-.0599-.17967-.1442-.23597-.2448-.05629-.1005-.08571-.2139-.08541-.3291.00029-.1152.0303-.2284.08712-.3286l2.40997-2h15.43c.2285.0066.4523-.0652.6342-.2034.182-.1383.3111-.3348.3658-.5566l3.2-13.00003c.0304-.14909.0264-.30314-.0117-.45044s-.1094-.28396-.2083-.39956Z"/>
                                </svg>
                            </a>
                        </div>
                    </div> 
                    
                </div>

                <div class="block md:hidden flex items-center mr-2">
                    <button type="button" @click="mobileHeader = !mobileHeader">
                        <svg class="text-white w-4 md:w-6 h-4 md:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path></svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- mobile header -->
            <div class="md:hidden" v-if="mobileHeader">
                <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
                <div>   
                    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 right-0 z-50 outline-none focus:outline-none ">
                        <div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
                            <div class="shadow-lg rounded-l-md h-screen relative flex  bg-white outline-none focus:outline-none mx-auto border-l-2 border-y-2 border-blue-200 relative">        
                                <div class="flex flex-col px-4 py-2 space-y-3">
                                    <a href="/" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >{{$t('home')}}</a>
                                    <a href="search" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >{{$t('art-gallery')}}</a>
                                    <a href="search" class="nav-link hover:bg-opacity-50 hover:bg-bgBlue px-1 lg:px-2 py-1.5 rounded" >{{$t('photo-gallery')}}</a>
                                    <a href="javascript:void(0)" title="coming soon..." class="nav-link text-gray-400 px-1 lg:px-2 py-1.5 rounded" >{{$t('exhibit')}}</a>
                                    <a href="javascript:void(0)" title="coming soon..." class="nav-link text-gray-400 px-1 lg:px-2 py-1.5 rounded" >{{$t('art-photo-exhibition')}}</a>
                                </div>

                                <div class="absolute inset-y-2 right-2">
                                    <button type="button" @click="mobileHeader = !mobileHeader">
                                        <svg class="ill-current text-black w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import localforage from 'localforage';
export default  ({
    data: function() {
        return {
            intervalId: '',
            locale: localStorage.getItem("localforage/smartartadmin/locale") ? localStorage.getItem("localforage/smartartadmin/locale").replace(/['"]+/g, '') : 'en',
            languageName: (localStorage.getItem('selectedLanguage') == '' || localStorage.getItem('selectedLanguage') === 'null') ? 'English' : localStorage.getItem('selectedLanguage'),
            languages: [],
            dropLang: false,
            dropUser: false,
            mobileHeader: false,        
            profile_image: 'images/user.png',
            intervalId:'',
            notifications:[],
            notificationCount:0,
            dropNotification:false
        }
    },
    components: {
    },
    computed: {
        ...mapGetters({
        }),
           
    },
    watch: {
        
    },
    methods: {
        ...mapActions({
            languagesList:'languagesList'
            //fetchGeneral: 'footer/fetchGeneral',

        }),
        ...mapMutations({
        }),

        async getLanguages(){
            try{
                const res = await this.$store.dispatch("getLanguages",{});
                if(res.success){
                    this.languages = res.data;
                }
            }catch(e){
                console.log(e);
            }
        },

        selectLang(lang_slug,lang_name) {
            this.locale = lang_slug;
            this.languageName = lang_name;
            localStorage.setItem('selectedLanguage', lang_name);
            localforage.setItem('locale', this.locale);
            window.location = window.location.href
        },
        
        funcSignout(){
            const email = localStorage.getItem('email');
            const password = localStorage.getItem('password');
            const keepMeLogin = localStorage.getItem('keepMeLoggedIn');
            const selectedLanguage = localStorage.getItem('selectedLanguage');
            const local = localStorage.getItem('localforage/smartartadmin/locale');
            localStorage.clear();
            if(keepMeLogin){
                localStorage.setItem('email', email);
                localStorage.setItem('password', password);
                localStorage.setItem('keepMeLoggedIn', keepMeLogin);
            }
            localStorage.setItem('selectedLanguage', selectedLanguage);
            localStorage.setItem('localforage/smartartadmin/locale', local);
            window.location.href = process.env.VUE_APP_LOCAL_API;
        },

        async getSiteSettingsClient(){
            //this.gIndicator('start');
            try{
                const res = await this.$store.dispatch("auth/getSiteSettingsClient");
                let applicable_currency = res.data.applicable_currency || 'USD';
                localStorage.setItem("applicable_currency", applicable_currency);
            } catch(error){
                console.log("Error in response", error);
            }finally{
                //this.gIndicator('stop');
            }
        },
        async getNotifications(){
            try{
                const res = await this.$store.dispatch("getBellNotification");
                if(res.success){
                    this.notifications = res.data.data;
                    this.notificationCount = res.count;
                }
                
            } catch(error){
                console.log("Error in response", error);
            }
        },
        async markAsSeen(data){
            try{
                const res = await this.$store.dispatch("markSeenBellNotification",{payload:{id:data.id}});
                if(res.success){
                    window.open(process.env.VUE_APP_LOCAL_API+data.link,'frameName');
                }
                
            } catch(error){
                console.log("Error in response", error);
            }
        },
        async loadfunction(){
            clearInterval(this.intervalId);
            await this.getLanguages();
            this.getNotifications();
            this.getSiteSettingsClient();
            const getData = JSON.parse(localStorage.getItem('data'));
            this.profile_image = getData.profile_image;
        }
    },
   

    mounted() {
        this.intervalId = setInterval(() => {
            this.loadfunction();
        }, 1000);
    },
    
});
</script>